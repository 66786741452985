<template>
  <div class="pay">
    <Header>易宝钱包</Header>
    <iframe :src="url" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      yeePayWalletState: 0,
      url: "",
    };
  },
  mounted() {
    this.$api.Userinfo({ token: localStorage.getItem("token") }).then((res) => {
      if (res.code === 0) {
        this.yeePayWalletState = res.data.yeePayWalletState;
        this.init();
      }
    });
  },
  methods: {
    init() {
      // 注册
      if (!this.yeePayWalletState) {
        this.$api
          .createWallet({ token: localStorage.getItem("token") })
          .then((e) => {
            this.url = e.url;
          });
      } else {
        this.$api
          .userPayWallet({ token: localStorage.getItem("token") })
          .then((e) => {
            this.url = e.url;
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pay {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
  }
}
</style>